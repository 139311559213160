.user__avatar {
  width: 40px;
  height: 40px;

  color: var(--black);
  font-weight: 700;
}

.user__info__role {
  font-size: 12px;
  color: var(--grey);
  text-transform: capitalize;
}
