html,
body {
  min-height: 100vh;
}

:root {
  --white: #ffffffff;
  --black: #212b36;
  --grey: #c1c1c1;
  --light-grey: #f1f5f9;
  --blue: #00b4de;
}

.spinner,
.empty__placeholder {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: translate(-50%, -50%);
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 99px;
}
