.available__session__wrapper {
  margin-bottom: 15px;
}
.available__session__time {
  width: 100%;
}
.drawer__button__default {
  font-size: 12px;
  font-weight: 500;
  line-height: 13.2px;

  padding: 0;
  margin-left: -24px !important;
}

.drawer__button:hover {
  color: var(--black) !important;
}

.drawer__button span {
  color: var(--black) !important;
}
