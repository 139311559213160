.dashboard__container {
  height: 100vh;
}
.sider__wrapper {
  background-color: var(--white) !important;
}

.header {
  background-color: var(--white) !important;
}

.logo {
  width: 140px;
  padding: 20px 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

.dropdown_btn {
  background-color: transparent !important;
}
