.available__time__slot__date__picker {
  width: 50% !important;
  margin-bottom: 10px;
  border: none;
}

.available__time__slot__time__picker {
  width: calc(100% - 56px) !important;
  margin-bottom: 10px;
}
