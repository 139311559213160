.session__creator__wrapper {
  margin-bottom: 15px;
}

.session__creator__time {
  width: 45% !important;
}

.session__creator__icon {
  flex-shrink: 0 !important;
}
